exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-henkilot-js": () => import("./../../../src/pages/henkilot.js" /* webpackChunkName: "component---src-pages-henkilot-js" */),
  "component---src-pages-hinnasto-js": () => import("./../../../src/pages/hinnasto.js" /* webpackChunkName: "component---src-pages-hinnasto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-julkaisut-js": () => import("./../../../src/pages/julkaisut.js" /* webpackChunkName: "component---src-pages-julkaisut-js" */),
  "component---src-pages-myynnissa-js": () => import("./../../../src/pages/myynnissa.js" /* webpackChunkName: "component---src-pages-myynnissa-js" */),
  "component---src-pages-palvelut-js": () => import("./../../../src/pages/palvelut.js" /* webpackChunkName: "component---src-pages-palvelut-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */)
}

